<template>
	<div class="contact-mobile" v-if="isMobile">
		<img src="../assets/img/banner_home.png" class="img-banner">
		<div class="column-me row-center">
			<div class="row-me center-all margin-top50">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left10 margin-right10">
					<div class="title">联系我们</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="subtitle">CONTACT US</div>
			<div class="column-me row-center margin-top60">
				<div class="map-div" id="container"></div>
				<div class="right-div column-me margin-left20 margin-top50 flex-sb flex1" v-if="homeItem">
					<div class="title0">青岛红尾狐俱乐部有限公司</div>
					<div class="row-me margin-top20">
						<div class="title1">地址：</div>
						<div class="title2">{{homeItem.conf.address}}</div>
					</div>
					<div class="row-me">
						<div class="title1">电话：</div>
						<div class="title2">{{homeItem.conf.contacts}}</div>
					</div>
					<div class="row-me">
						<div class="title1">邮箱：</div>
						<div class="title2">{{homeItem.conf.email}}</div>
					</div>
					<div class="row-me">
						<div class="title1">加盟热线：</div>
						<div class="title2">{{homeItem.conf.join_phone}}</div>
					</div>
					<div class="row-me">
						<div class="title1">招商人员二维码</div>
					</div>
					<el-image :src="homeItem.conf.contacts_qrcode" :preview-src-list="[homeItem.conf.contacts_qrcode]"
						fit="cover" hide-on-click-modal class="img-code margin-top50"></el-image>
				</div>
			</div>
		</div>
	</div>
	<div class="contact" v-else>
		<img src="../assets/img/banner_home.png" class="img-banner">
		<div class="column-me row-center">
			<div class="row-me center-all margin-top90">
				<img src="../assets/img/title_line.png" class="img-line">
				<div class="column-me row-center margin-left60 margin-right60">
					<div class="title">联系我们</div>
					<div class="subtitle">CONTACT US</div>
				</div>
				<img src="../assets/img/title_line.png" class="img-line">
			</div>
			<div class="row-me margin-top100 margin-left100 margin-right100">
				<div class="map-div" id="container"></div>
				<div class="right-div column-me flex-sb margin-left50 flex1" v-if="homeItem">
					<div class="title0">青岛红尾狐俱乐部有限公司</div>
					<div class="row-me">
						<div class="title1">地址：</div>
						<div class="title2">{{homeItem.conf.address}}</div>
					</div>
					<div class="row-me">
						<div class="title1">电话：</div>
						<div class="title2">{{homeItem.conf.contacts}}</div>
					</div>
					<div class="row-me">
						<div class="title1">邮箱：</div>
						<div class="title2">{{homeItem.conf.email}}</div>
					</div>
					<div class="row-me">
						<div class="title1">加盟热线：</div>
						<div class="title2">{{homeItem.conf.join_phone}}</div>
					</div>
					<div class="row-me">
						<div class="title1">招商人员二维码</div>
					</div>
					<el-image :src="homeItem.conf.contacts_qrcode" :preview-src-list="[homeItem.conf.contacts_qrcode]"
						fit="cover" hide-on-click-modal class="img-code"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AMap from 'AMap' // 引入高德地图
	export default {
		components: {

		},
		data() {
			return {
				homeItem: null,
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {
			this.getHomeData()
		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
			this.$nextTick(() => {
				if (this.isIos()) {
					this.initIos()
				} else {
					this.init()
				}
			})
		},
		// 事件方法执行
		methods: {
			init() {
				var map = new AMap.Map('container', {
					resizeEnable: true,
					center: [120.161397, 35.964028],
					zoom: 17,
					viewMode: '3D' //使用3D视图
				});
				var marker = new AMap.Marker({
					position: [120.161397, 35.964028], //位置
					title: '红尾狐俱乐部',
					icon: require('../assets/img/position.png'),
					offset: new AMap.Pixel(-30, -40)
				})
				map.add(marker); //添加到地图
				let content =
					'<div class="map-info-window-content row-me center-all">' +
					'<div class="map-company-name">红尾狐俱乐部</div>' +
					'</div>';
				if (this.isMobile) {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 120)
					});
					infoWindow.open(map, marker.getPosition());
				} else {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 60)
					});
					infoWindow.open(map, marker.getPosition());
				}
			},
			initIos() {
				var map = new AMap.Map('container', {
					resizeEnable: true,
					center: [120.161397, 35.964028],
					zoom: 19,
					viewMode: '3D' //使用3D视图
				});
				var marker = new AMap.Marker({
					position: [120.161397, 35.964028], //位置
					title: '红尾狐俱乐部',
					icon: new AMap.Icon({
						image: require('../assets/img/position.png'),
						size: new AMap.Size(170, 170), //图标所处区域大小
						imageSize: new AMap.Size(170, 170) //图标大小
					}),
					offset: new AMap.Pixel(-100, -200)
				})
				map.add(marker); //添加到地图
				let content =
					'<div class="map-info-window-content row-me center-all">' +
					'<div class="map-company-name">红尾狐俱乐部</div>' +
					'</div>';
				if (this.isMobile) {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 350)
					});
					infoWindow.open(map, marker.getPosition());
				} else {
					var infoWindow = new AMap.InfoWindow({
						isCustom: true, //使用自定义窗体
						showShadow: true,
						content: content,
						offset: new AMap.Pixel(-10, 60)
					});
					infoWindow.open(map, marker.getPosition());
				}
			},
			//是否苹果手机
			isIos() {
				let ua = navigator.userAgent.toLowerCase()
				return ua.indexOf('iphone') !== -1
			},
			//获取数据
			getHomeData() {
				this.$postRequest('/api/index/index', {}).then((res) => {
					if (res) {
						this.homeItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {},
		activated() {
			this.getHomeData()
		}
	}
</script>

<style lang="scss" scoped>
	.contact-mobile {
		padding-bottom: 150px;

		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.img-line {
			width: 80px;
		}

		.title {
			font-size: 28px;
			color: #000000;
			letter-spacing: 2px;
			margin-bottom: 10px;
		}

		.subtitle {
			font-size: 18px;
			color: #666666;
		}

		.map-div {
			width: 330px;
			height: 300px;
			border-radius: 20px;
			box-shadow: 0 7px 10px #FADA0F;
		}

		.right-div {}

		.title0 {
			font-size: 25px;
			font-weight: bold;
			color: #000000;
			letter-spacing: 2px;
		}

		.title1 {
			font-size: 25px;
			line-height: 50px;
			color: #666666;
		}

		.title2 {
			font-size: 25px;
			color: #333333;
			line-height: 50px;
			flex: 1;
		}

		.img-code {
			width: 131px;
			height: 131px;
			object-fit: fill;
		}
	}

	.contact {
		padding-bottom: 150px;

		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.img-line {
			width: 145px;
			object-fit: scale-down;
			margin-bottom: 10px;
		}

		.title {
			font-size: 48px;
			color: #000000;
			letter-spacing: 5px;
		}

		.subtitle {
			font-size: 24px;
			color: #666666;
		}

		.map-div {
			width: 890px;
			height: 615px;
			border-radius: 20px;
			box-shadow: 0 7px 10px #FADA0F;
		}

		.right-div {
			height: 615px;
		}

		.title0 {
			font-size: 36px;
			font-weight: bold;
			color: #000000;
			letter-spacing: 2px;
		}

		.title1 {
			font-size: 30px;
			line-height: 50px;
			color: #666666;
		}

		.title2 {
			font-size: 30px;
			color: #333333;
			line-height: 50px;
			flex: 1;
		}

		.img-code {
			width: 131px;
			height: 131px;
		}
	}
</style>
